import YbCoreTypography from '@yubi/yb-core-typography';
import LenderContacts from 'Components/LenderContacts/LenderContacts.component';
import SadIcon from 'Components/SadIcon';
import { useBodyPosition } from 'hooks/browser/useBodyPosition';
import { WHITE_COLOR } from 'Shared/constants';
import { FlexDiv } from 'Shared/elements';
import { useAppSelector } from 'Shared/utils';
import styled from 'styled-components';

interface ErrorPageProps {
  errorMessage?: string;
  hideContact?: boolean;
  isLinkExpired?: boolean;
  hideMainMessage?: boolean;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage,
  hideContact = false,
  isLinkExpired,
  hideMainMessage = false,
}) => {
  const clientId = useAppSelector<string>(
    ({ borrowerWorkflow }) => borrowerWorkflow.workflowDetails.client.clientId
  );

  const { left, bodyWidth } = useBodyPosition();

  return (
    <FlexDivHeight position="center" align="center" data-testid="error-page">
      <FlexDiv margin="0 0 5rem 0" padding="0 2rem 2rem 2rem" gap="8px">
        <SadIcon />
        {!hideMainMessage && (
          <YbCoreTypography size={20}>
            {isLinkExpired ? 'Link Expired!' : 'Error!'}
          </YbCoreTypography>
        )}
        <YbCoreTypography color="#667085" size={14} align="center">
          {errorMessage}
        </YbCoreTypography>
      </FlexDiv>

      {clientId && !hideContact && (
        <FlexDivPosition left={`${left}px`} bodyWidth={`${bodyWidth}px`}>
          <LenderContacts />
        </FlexDivPosition>
      )}
    </FlexDivHeight>
  );
};

const FlexDivHeight = styled(FlexDiv)`
  height: 100svh;
  background: ${WHITE_COLOR};
  display: flex;
`;

const FlexDivPosition = styled(FlexDiv)<{ left: string; bodyWidth: string }>`
  position: absolute;
  bottom: 0px;
  left: ${({ left }) => left};
  width: ${({ bodyWidth }) => bodyWidth};
`;
ErrorPage.defaultProps = {
  errorMessage: 'Something went wrong. Please try again later',
};
export default ErrorPage;
